import React from 'react';
import ReactDOM from 'react-dom';
import LinkedInDeActiveIcon from '../Assets/Social Icons/LinkedIn DeActive.png';
import LinkedInActiveIcon from '../Assets/Social Icons/LinkedIn Active.png';
import InstagramDeActiveIcon from '../Assets/Social Icons/Instagram DeActive.png';
import InstagramActiveIcon from '../Assets/Social Icons/Instagram Active.png';
import HoverImage from "react-hover-image";

class Footer extends React.Component {
  render() {
    return(
      
        <div style={Styles.socialList}>
          <p style={Styles.copyright}>Copyright - OWLIUM</p>
            {Object.entries(this.props.socials).map(([network, link])=>{
              switch(network) {
                case 'LinkedIn':
                  return (
                    <div style={Styles.socialIcon}>
                      <a href={link} >
                        <HoverImage src={LinkedInDeActiveIcon} hoverSrc={LinkedInActiveIcon} style={Styles.image}/>
                      </a>
                    </div>
                  );
                  break;
                case 'Instagram':
                  return (
                    <div style={Styles.socialIcon}>
                      <a href={link} >
                        <HoverImage src={InstagramDeActiveIcon} hoverSrc={InstagramActiveIcon} style={Styles.image}/>
                      </a>
                    </div>
                  );
                  break;
                default:
                  break;
                }
              })
            }
          </div>
    );
  }
}

const Styles = {
  container : {
    display : 'flex',
    flexDirection  : 'row',
    justifyContent : 'center',
    alignItems : 'flexp-start',
    alignContent : 'space-around',
    flexWrap : 'wrap',
    textAlign : 'center',
  },
  socialList: {
    display : 'flex',
    flex : '1',
    flexDirection : 'row',
    justifyContent : 'center',
    alignItems  : 'flex-start',
    alignContent : 'center',
    flexWrap : 'wrap',
  },
  socialIcon : {
    margin : '1rem 0 1rem 1rem',
  },
  image: {
    height: '1.5rem',
  },
  copyright : {
    color : '#fff',
    fontSize : 'calc(0.5rem + 1vh)',
  },
}

export default Footer;