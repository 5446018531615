import ViroIcon from './Assets/bazarIconCircle.png';
import SlateIcon from './Assets/SlateIcon.png';
import UserPicMan from './Assets/userPic.png';
import HouseinPicDeActive from './Assets/HouseinPicDeActive.png';

const Data = 
  {
    "menu" : {
      "Home"  : "#",
      "About" : "#about",
      "Works" : "#works",
      "Team"  : "#team"
    },
    "sections" : {
      "Home"   : {"id" : 0},
      "About"  : {
        "id" : 1,
        "preTitle" : "a little about",
        "title" : "OWLIUM",
        "dash" : true,
        "darkTheme" : false
      },
      "Works"  : {
        "id" : 2,
        "preTitle" : "you may want to see",
        "title" : "Some of Our Works",
        "dash" : true,
        "darkTheme" : true
      },
      "Team"   : {
        "id" : 3,
        "preTitle" : "Know more about",
        "title" : "Our Team",
        "dash" : true,
        "darkTheme" : false
      },
      "Footer" : {
        "id" : 4,
        "darkTheme" : true
      }
    },
    "WorkItems" : [
      {"title" : "Viro",
        "icon" : ViroIcon,
        "OSes" : {
          "Android" : "https://play.google.com/store/apps/details?id=com.owliumstudio.viro",
          "Windows" : "http://www.owliumstudio.com",
          "iOS" : "http://www.itunes.com"
        },
        "description" : "After a fatal virus outbreak, human being is going to extinct. You should help Viro to fight against viruses and pass them to save children and population on earth.",
        "more" : "http://www.owliumstudio.com"
      },
      {"title" : "Slate",
        "icon" : SlateIcon,
        "OSes" : {
          "Android" : "http://www.google.com",
          "Windows" : "http://www.owliumstudio.com",
          "iOS" : "http://www.itunes.com"
        }
        ,
        "description" : "Establish a sustainable business and manage to growth. Build up your village and spread joy and happiness. The \"Slate\" is a strategy/simulation/management game solution to learn economy and business management.",
        "more" : "http://www.owliumstudio.com"
      }],
    "teamMembers" : [
      /*
      { "name" : "Antoine Fakhriyat",
        "picture" : UserPicMan,
        "title" : "Marketing Director",
        "description" : "Antoine is experienced in marketing and sales. He is angel investor in Owlium. Antoine researches the market and find new posibilities. He always comes up with great ideas. ",
        "socials" : {
          "LinkedIn" : "http://www.linkedin.com",
          "Instagram" : "http://www.instagram.com",
          "Skype" : "http://www.skype.com"
        }
      },
      */
      { "name" : "Housein Asadi",
        "picture" : HouseinPicDeActive,
        "title" : "Founder & CEO",
        //"description" : "Housein is a Designer! he has worked years as product designer, graphic & identitiy designer, 3d modeler, brand consultant and strategist, web & app designer/ developer. he finaly find himself as an indie game developer! ",
          "description" : "Nobody knows exactly what he does, but one thing is certain: he’s a dreamer. After years of working as a programmer, product designer, and artist, he has finally found his calling as an indie game developer!",
          "socials" : {
          "LinkedIn" : "https://www.linkedin.com/in/houseinasadi/",
          "Instagram" : "https://www.instagram.com/housein.asadi/",
          "Site" : "https://www.houseinasadi.com"
        }
      }
    ],
    "brandSocials" : {
      "LinkedIn" : "http://www.linkedin.com",
      "Instagram" : "http://www.instagram.com"
    },
    "about" : "OWLIUM Studio is a small team of enthusiastic individuals who are skilled in design and tech fields. We are pointing to no certain direction but our main concern is to show the posibilities and guide people to choose their own path wisely. We saw \"ignorance\" as the most destructive disease and the root cause of all human suffering and violence. OWLIUM's main purpose is to help people especially youngsters to think more, read more, see more. through making games and apps that showing them new thoughts from new angles to make a spark in their brain to encourage them to living consciously and purposfully."
  }
/*(When we do not know who we are, where we are going, what we need and what process we are part of,
we become angry, greedy, selfish and violent. If we see the world, from a holistic perspective, 
if consider not only ourselfs but even the whole humanity as a part of a larger system. 
if we had have defined our purpose and meaning. we will know how to act and how to live in line with )*/

export default Data;