import React from 'react';
import ReactDOM from 'react-dom';
import LinkedInDeActiveIcon from '../Assets/Social Icons/LinkedIn DeActive.png';
import LinkedInActiveIcon from '../Assets/Social Icons/LinkedIn Active.png';
import InstagramDeActiveIcon from '../Assets/Social Icons/Instagram DeActive.png';
import InstagramActiveIcon from '../Assets/Social Icons/Instagram Active.png';
import SkypeDeActiveIcon from '../Assets/Social Icons/Skype DeActive.png';
import SkypeActiveIcon from '../Assets/Social Icons/Skype Active.png';
import SiteDeActiveIcon from '../Assets/Social Icons/Site DeActive.png';
import SiteActiveIcon from '../Assets/Social Icons/Site Active.png';
import HoverImage from "react-hover-image";

class TeamMember extends React.Component {
  render(){
    return (
        <div style={Styles.container}>
          <h4 style={Styles.name}>{this.props.name}</h4>
          <div style={Styles.picture}>
            <img src={this.props.source} style={Styles.memberPic}></img>
          </div>
          <h4 style={Styles.title}>{this.props.title}</h4>
          <div style={Styles.description}>
            <p>{this.props.description}</p>
          </div>
          <div style={Styles.socialList}>
            {Object.entries(this.props.socials).map(([network, link])=>{
              switch(network) {
                case 'LinkedIn':
                  return (
                    <div style={Styles.socialIcon}>
                      <a href={link} >
                        <HoverImage src={LinkedInDeActiveIcon} hoverSrc={LinkedInActiveIcon} style={Styles.image}/>
                      </a>
                    </div>
                  );
                  break;
                case 'Instagram':
                  return (
                    <div style={Styles.socialIcon}>
                      <a href={link} >
                        <HoverImage src={InstagramDeActiveIcon} hoverSrc={InstagramActiveIcon} style={Styles.image}/>
                      </a>
                    </div>
                  );
                  break;
                case 'Skype':
                  return (
                    <div style={Styles.socialIcon}>
                      <a href={link} >
                        <HoverImage src={SkypeDeActiveIcon} hoverSrc={SkypeActiveIcon} style={Styles.image}/>
                      </a>
                    </div>
                  );
                  break;
                case 'Site':
                  return (
                    <div style={Styles.socialIcon}>
                      <a href={link} >
                        <HoverImage src={SiteDeActiveIcon} hoverSrc={SiteActiveIcon} style={Styles.image}/>
                      </a>
                    </div>
                  );
                  break;
                default:
                  break;
                }
              })
            }
          </div>
        </div>
    );
  }
}

const Styles = {
  container  :{
    display: 'table-cell',
    //flex : '2',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    //minHeight : '70vh',
    //width : '25%',
    //maxWidth : '25%',
    //width: '40vh',
    minWidth : '25%',
    maxWidth : '40vh',
    backgroundColor: '#eee',
    margin: 'calc(1rem + 1vh)',
    padding: '1rem 2vh',
    borderRadius: '2rem',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4)',
  },
  name: {
    fontSize : 'calc(1rem + 1vh)',
    textAlign: 'center',
    margin: '0',
    color : '#282828',
    //maxWidth : '90%',
  },
  picture : {
    display: 'flex',
    flexDirection : 'column',
    justifyContent : 'center',
    alignItems : 'center',
    
  },
  memberPic : {
    margin : '1rem',
    width : '8rem',
    borderRadius: '50%',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4)',
  },
  title: {
    fontSize : 'calc(0.8rem + 1vh)',
    textAlign: 'center',
    margin: '0',
    color : '#00aeae',
  },
  image: {
    height: '1.5rem',
  },
  description: {
    fontSize: 'calc(0.5rem + 1vh)',
    padding: '0 5%',
    height : '25vh',
    overflow : 'hidden',
  },
  socialList: {
    display : 'flex',
    flex : '1',
    flexDirection : 'row',
    justifyContent : 'center',
    alignItems  : 'flex-start',
    alignContent : 'space-around',
    flexWrap : 'wrap',
  },
  socialIcon : {
    margin : '1rem',
  },
}
export default TeamMember;