import React from 'react';
import ReactDOM from 'react-dom';

class SectionTitle extends React.Component {
  
  render() {
    const theme = this.props.darkTheme ? {color : '#ddd'} : {color : '#0f8c79'};
    const titleStyle = {...Styles.title, ...theme};
    const preTitleStyle = {...Styles.preTitle, ...theme};
    return(
      <div style={Styles.container}>
        <h5 style={preTitleStyle}>{this.props.preTitle}</h5>
        <h3 style={titleStyle}>{this.props.title}</h3>
        <hr style={Styles.partLine}></hr>
      </div>
    );
  }
}

const Styles = {
  container : {
    marginBottom : '3rem',
  },
  preTitle: {
    textAlign :'center',
    fontSize: '1rem',
    margin : '0',
  },
  title : {
    textAlign : "center",
    fontSize : "calc(1rem + 3vh)",
    margin : '0',
    padding : '2vh',
  },
  partLine : {
    width : '20vh', 
    textAlign : 'center', 
    color : '#0f8c79',
  }
}

export default SectionTitle;