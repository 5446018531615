import React from "react";
import logo2 from "./Assets/owliumHeroLogo-03.png";
import "./App.css";
import WorkItem from "./Components/WorkItem";
import TeamMember from "./Components/TeamMember";
import SectionItem from "./Components/SectionTitle";
import Footer from "./Components/Footer";
import Data from "./data";
import ParticleConfig from "./Components/TsParticlesConfig";
import Particles from "react-tsparticles";

function App() {
  console.log(Data.WorkItems[0].description);
  return (
    <div style={Style.mainContainer}>
      <div style={Style.container}>
         <header className="App-header">
            <Particles options={ParticleConfig} style={Style.particle} />
            <div style={Style.contentContainer}>
              <img src={logo2} style={Style.logo} alt=" Owlium logo" />
            </div>
          </header>
      </div>
      <div style={Style.container}>
        <div style={Style.contentContainer}>
          <SectionItem
            preTitle={Data.sections.About.preTitle}
            title={Data.sections.About.title}
          />
          <p style={Style.textBlock}>{Data.about}</p>
        </div>
        
      </div>
      <div style={Style.darkerContainer}>
        <div style={Style.contentContainer}>
          <SectionItem
            preTitle={Data.sections.Works.preTitle}
            title={Data.sections.Works.title}
            darkTheme={Data.sections.Works.darkTheme}
          />
          <div style={Style.works}>
            {Data.WorkItems.map((item) => (
              <WorkItem
                title={item.title}
                source={item.icon}
                oses={item.OSes}
                description={item.description}
              />
            ))}
          </div>
        </div>
      </div>
      <div style={Style.container}>
        <div style={Style.contentContainer}>
          <SectionItem
            preTitle={Data.sections.Team.preTitle}
            title={Data.sections.Team.title}
            darkTheme={Data.sections.Team.darkTheme}
          />
          <div style={Style.team}>
            {Data.teamMembers.map((member) => (
              <TeamMember
                name={member.name}
                source={member.picture}
                title={member.title}
                socials={member.socials}
                description={member.description}
              />
            ))}
          </div>
        </div>
      </div>
      <div style={Style.footerStyle}>
        <div style={Style.contentContainer}>
          <Footer socials={Data.brandSocials} />
        </div>
      </div>
    </div>
  );
}

const Style = {
  mainContainer: {
    
  },
  container: {
    marginBottom: "3rem",
  },
  darkerContainer: {
    marginBottom: "5rem",
    backgroundColor: "#132828",
    padding: "2rem 0",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "space-around",
    maxWidth: "1200px",
    margin: "0 auto",
  },
  logo: {
    height: "15vw",
    padding: "5px",
    minWidth: "15vh",
    minHeight : "15vh",
  },
  brand: {
    fontSize: "1.3rem",
    margin: "1rem",
    lineHeight: "0.5rem",
    color: "#BBB",
  },
  works: {
    display: "flex",
    flex: "1",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    alignContent: "space-around",
    flexWrap: "wrap",
    margin: "0 2vh",
    textAlign: "center",
  },
  team: {
    display: "flex",
    flex: "1",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    alignContent: "space-around",
    flexWrap: "wrap",
    margin: "0 2vh",
    textAlign: "center",
  },
  lightTitle: {
    textAlign: "center",
    fontSize: "2rem",
    color: "#fff",
  },
  textBlock: {
    padding: "1rem 5%",
    margin: "5px 10px",
    textIndent: "2rem",
    fontSize: "1rem",
    color: "#333",
  },
  footerStyle: {
    textAlign: "center",
    color: "#ddd",
    padding: "20px",
    backgroundColor: "#132828",
  },
  particle: {
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    minHeight: "100vh",
  },
};

export default App;
