import React from 'react';
import ReactDOM from 'react-dom';
import WinDeActiveIcon from '../Assets/OS Icons/Windows DeActive.png';
import WinActiveIcon from '../Assets/OS Icons/Windows Active.png';
import AndroidDeActiveIcon from '../Assets/OS Icons/Android DeActive.png';
import AndroidActiveIcon from '../Assets/OS Icons/Android Active.png';
import iOSDeActiveIcon from '../Assets/OS Icons/iOS DeActive.png';
import iOSActiveIcon from '../Assets/OS Icons/iOS Active.png';
import HoverImage from "react-hover-image";
import Arrow from '../Assets/arrow-02.png';

class WorkItem extends React.Component {
  render() {
    
    return (
      <div style={Styles.container}>
        <h4 style={Styles.appTitle}>{this.props.title}</h4>
        <div style={Styles.appHeader}>
            <img src={this.props.source} style={Styles.appIcon}></img>
        </div>
        <div style={Styles.osList}>
        {Object.entries(this.props.oses).map(([os, link])=>{
              switch(os) {
                case 'Android':
                  return (
                    <div style={Styles.osIcon}>
                      <a href={link} >
                        <HoverImage src={AndroidDeActiveIcon} hoverSrc={AndroidActiveIcon} style={Styles.image}/>
                      </a>
                    </div>
                  );
                  break;
                case 'iOS':
                  return (
                    <div style={Styles.osIcon}>
                      <a href={link} >
                        <HoverImage src={iOSDeActiveIcon} hoverSrc={iOSActiveIcon} style={Styles.image}/>
                      </a>
                    </div>
                  );
                  break;
                case 'Windows':
                  return (
                    <div style={Styles.osIcon}>
                      <a href={link} >
                        <HoverImage src={WinDeActiveIcon} hoverSrc={WinActiveIcon} style={Styles.image}/>
                      </a>
                    </div>
                  );
                  break;
                default:
                  break;
                }
              })
            }
        </div>
        <div style={Styles.description}>
          <p>{this.props.description}</p>
        </div>
        <a style={Styles.moreLink} href={'http://www.owliumstudio.com'}>
          <div style={Styles.moreRow}>
            <span style={Styles.more}><strong>More</strong></span>
            <img style={Styles.moreImg} src={Arrow}></img>
          </div>
        </a>
      </div>
    );
  }
}


const Styles = {
  container  :{
    display: 'table-cell',
    flex : '2',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    //minHeight : '70vh',
    maxWidth : '50vh',
    backgroundColor: '#eee',
    margin: 'calc(1rem + 1vh)',
    padding: '1rem 2vh',
    borderRadius: '2rem',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4)',
  },
  appHeader : {
    display: 'flex',
    flexDirection : 'column',
    justifyContent : 'center',
    alignItems : 'center',
    
  },
  appIcon : {
    margin : '1rem',
    height: '8rem',
    borderRadius: '50%',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4)',
  },
  image: {
    height: '2.5rem',
  },
  osList: {
    display : 'flex',
    flex : '1',
    flexDirection : 'row',
    justifyContent : 'center',
    alignItems  : 'flex-start',
    alignContent : 'space-around',
    flexWrap : 'wrap',

  },
  osIcon : {
    margin : '1rem',
  },
  appTitle: {
    fontSize : 'calc(1rem + 2vh)',
    textAlign: 'center',
    margin: '0',
  },
  description: {
    fontSize: 'calc(0.5rem + 1vh)',
    padding: '0 5%',
    height : '25vh',
    overflow : 'hidden',
  },
  moreLink : {
    textDecoration : 'none',

  },
  moreRow : {
    
  },
  more : {
    color : '#11b5a0'
  },
  moreImg : {
    position : 'relative',
    top : '5px',
    left : '5px',
  },
}

export default WorkItem;